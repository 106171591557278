const Hero = ({
  heroStyle,
  title,
  desc,
  primaryBtnHref,
  primaryBtnText,
  secondaryBtnHref,
  secondaryBtnText,
  textBelow,
}) => {
  return (
    <div className={`${heroStyle} bg-[#091f2c4d] `}>
      <div className="App-hero min-h-[781px]">
        <section className="container ">
          <div className="App-hero_content mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-16">
            <div className="items-center justify-center mt-[137px] ">
              <div className="lg:py-20 max-w-[784px] text-center ">
                <h1 className="mb-4"> {title}</h1>
                <span className="emphasis_para">{desc}</span>

                <div className="mt-7 block sm:flex justify-center gap-5">
                  <a
                    href={primaryBtnHref}
                    className="primary-btn inline-flex items-center port:!w-[100%]"
                  >
                    {primaryBtnText}
                  </a>
                  <a
                    href={secondaryBtnHref}
                    className="secondary-btn inline-flex items-center port:!w-[100%] port:mt-4"
                  >
                    {secondaryBtnText}
                  </a>
                </div>
                <p className="mt-8 finePrint_para">{textBelow}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Hero;
