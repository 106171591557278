const HalfBody = ({ title, content, image }) => {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:gap-16">
      <div className="lg:py-5">
        <h2 className="inline-flex items-baseline max-w-[546px] gap-6">
          {title}
        </h2>

        <p className="mt-4">{content}</p>
      </div>
      <div>{image}</div>
    </div>
  );
};
export default HalfBody;
