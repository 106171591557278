import { Navbar } from "flowbite-react";
import LogoDefault from "../icons/go-logo.svg";
import CustomModal from "./custom-modal";

const Header = ({
  absolute,
  logo,
  btnHref,
  btnText,
  modal,
  modalBtnName,
  modalTitle,
  modalDesc,
  actionBtnHref,
  actionBtn,
}) => {
  return (
    <div
      className={` ${
        absolute ? "absolute top-0 left-0 right-0" : ""
      } px-2 lg:px-12 header  `}
    >
      <div className="container">
        <Navbar fluid={true} rounded={true}>
          <Navbar.Brand href="/" className={!modal ? "port:m-auto" : ""}>
            {" "}
            {logo !== undefined && logo !== "" ? (
              logo
            ) : (
              <img
                alt="logo"
                src={LogoDefault}
                className="w-[194.11px] h-[23.38px]"
              />
            )}
          </Navbar.Brand>
          <div
            className={`md:order-2 md:flex sm:ml-auto ${
              !modal ? "hidden" : ""
            }`}
          >
            <div className="flex md:order-2">
              {modal ? (
                <CustomModal
                  modalBtnName={modalBtnName}
                  modalTitle={modalTitle}
                  modalDesc={modalDesc}
                  actionBtnHref={actionBtnHref}
                  actionBtn={actionBtn}
                />
              ) : (
                <a
                  href={btnHref}
                  className="secondary-btn-sml inline-flex items-center"
                >
                  {btnText}
                </a>
              )}
            </div>
          </div>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;
