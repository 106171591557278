const Form = ({ formUrl, formTextBelow, customStyle }) => {
  return (
    <div className="md:w-[1035px] -mt-8">
      <div className="max-w-[785px] m-auto ">
        <p className="text-center">{formTextBelow}</p>
      </div>
      <embed
        src={formUrl}
        className={`flex h-[700px] sm:h-[610px] justify-center w-full bg-transparent ${customStyle}`}
      />
    </div>
  );
};
export default Form;
