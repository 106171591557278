import { Card } from "flowbite-react";

const CardItems = ({ obj }) => {
  return (
    <>
      {obj.map((item, index) => (
        <div key={index} className="max-w-sm cardItem">
          <Card>
            <div className="cardWrapper">
              {item.icon}
              <h4>{item.card_title}</h4>
              <p>{item.card_content}</p>
            </div>
          </Card>
        </div>
      ))}
    </>
  );
};

export default CardItems;
