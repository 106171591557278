import CardItems from "./lib/components/card";
import SiteFooter from "./lib/components/footer";
import Form from "./lib/components/form";
import HalfBody from "./lib/components/half-body";
import Header from "./lib/components/header";
import Hero from "./lib/components/hero";
import Section from "./lib/components/section";
import ThreeCol from "./lib/components/three-col";
import "./lib/styles/App.scss";
import icon from "./lib/icons/chart_data.svg";
import icon2 from "./lib/icons/area_chart.svg";
import icon3 from "./lib/icons/candlestick_chart.svg";
import Logo from "./lib/icons/go-logo.svg";
import { useAffiliateTracker } from "./lib/affiliate-tracker/useAffiliateTracker";

function App() {
  useAffiliateTracker(
    ["app.gomarkets.com", "gomarkets.eappform.com"],
    "primary-btn"
  );
  return (
    <div className="main">
      <div className="relative">
        <Header
          btnText="Open"
          btnHref=""
          logo={
            <img alt="logo" src={Logo} className="w-[194.11px] h-[23.38px]" />
          }
          absolute={true}
          modal={true}
          modalBtnName="Modal"
          modalTitle="Lorem Ipsum title"
          modalDesc={
            <>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
              mollis, enim eget pretium sollicitudin
            </>
          }
          actionBtnHref=""
          actionBtn="Continue"
        />
        <Hero
          title="Award-winning Education Services"
          desc={
            <>
              Daily webinars, timely news updates, learning content for every
              level of trader.
            </>
          }
          textBelow=""
          primaryBtnText="Register Now"
          primaryBtnHref="https://app.gomarkets.com/index.php"
          secondaryBtnText="Learn More"
          secondaryBtnHref="#"
          heroStyle="bg-cover bg-no-repeat bg-[url(/public/Mutiple-angle-long2.png)]"
        />
      </div>

      <Section title="From Beginner to Professional">
        <ThreeCol>
          <CardItems
            obj={[
              {
                icon: <img alt="logo" src={icon} className="w-9 h-9" />,
                card_title: "Daily market updates",
                card_content: "",
              },
              {
                icon: <img alt="logo" src={icon2} className="w-9 h-9" />,
                card_title: "Lorem ipsum me dolor excusi du",
                card_content: "",
              },
              {
                icon: <img alt="logo" src={icon3} className="w-9 h-9" />,
                card_title: "Lorem ipsum me dolor excusi du",
                card_content: "",
              },
            ]}
          />
        </ThreeCol>
      </Section>

      <Section title="">
        <HalfBody
          title=""
          content={
            <>
              GO Markets has education resources for every level of trader.
              Whether you're a seasoned trader looking for timely market
              updates, or just getting started and need dedicated support, we
              have the resources for you.
            </>
          }
          image={
            <img
              alt="hero"
              src={"Mask-group.png"}
              className="inset-0 object-cover"
            />
          }
        />
      </Section>

      <Section
        title="Register for award winning Education"
        customStyle={"bg-darkSix sm:rounded-[40px] !mb-[138px]"}
      >
        <Form
          formUrl="https://tfaforms.com/5072871"
          formTextBelow="Programs, webinars, analysis and more. Guide you to the best possible experience."
        />
      </Section>

      <SiteFooter
        logo={
          <img alt="logo" src={Logo} className="w-[194.11px] h-[23.38px]" />
        }
        ftrInfo={[
          {
            title: "CONTACT",
            subContent: [
              {
                content: "Australia",
                info: "1800 88 55 71",
                link: "tel:1800 88 55 71",
              },
              {
                content: "UK",
                info: "+44 203 637 7618",
                link: "tel:+44 203 637 7618",
              },
              {
                content: "CHINA",
                info: "400 120 8537",
                link: "tel:400 120 8537",
              },
              {
                content: "INTERNATIONAL",
                info: "+61 3 8566 7680",
                link: "tel:+61 3 8566 7680",
              },
            ],
          },
          {
            title: "SUPPORT",
            subContent: [
              {
                content: "Email",
                info: " support@gomarkets.com",
                link: "mailto:support@gomarkets.com",
              },
              {
                content: " New Accounts",
                info: "newaccounts@gomarkets.com",
                link: "mailto:newaccounts@gomarkets.com",
              },
            ],
          },
        ]}
        ftrInfo2={[
          {
            title: "LEGAL DOCS",
            content: [
              { title: "Privacy Policy", link: "/" },
              { title: "Terms and Conditions", link: "/" },
              { title: "Product Disclosure Statement", link: "/" },
              { title: "Financial Services Guide", link: "/" },
            ],
          },
        ]}
        textBottom={
          <>
            <span className="font-medium">
              Our products carry a high level of risk, including the risk of
              losing substantially more than your initial expenditure. Also, you
              do not own or have any rights to the underlying assets.
            </span>
            <br /> <br />
            GO Markets Pty Ltd does not guarantee the performance or return of
            capital. You should only trade in risk capital (capital you can
            afford to lose). Historical financial performance of any underlying
            instrument/market is no guarantee or indicator of future
            performance.
            <br /> <br /> Our services may not be suitable for all investors,
            ensure that you fully understand the risks involved, and seek
            independent advice if necessary. A Financial Services Guide (FSG)
            and Product Disclosure Statement (PDS) for our products are
            available from GO Markets Pty Ltd to download at this website, and
            hard copies can be obtained by contacting our offices. <br /> <br />{" "}
            Please note, your call may be recorded for training and monitoring
            purposes. Any advice provided to you on this website or by our
            representatives is general advice only, and does not take into
            account your objectives, financial situation or needs. Consider the
            appropriateness of our advice and our PDS documents before making
            any decision about using our products or services. <br /> <br />
            Information on this site is not directed at residents in any country
            or jurisdiction where such distribution or use would be contrary to
            local law or regulation. The GO Markets AFSL authorises us to
            provide financial services to people in Australia. <br /> <br />{" "}
            However, GO Markets complies with the obligations arising from our
            AFSL in respect of the financial services provided to all of our
            clients, including those who reside outside of Australia. This
            website is owned and operated by GO Markets. All contents © 2020 GO
            Markets Pty Ltd. All rights reserved. AFSL 254963. ABN 85 081 864
            039. ACN 081 864 039.
          </>
        }
      />
    </div>
  );
}

export default App;
